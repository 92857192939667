import { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';

import { useAccountList } from 'hooks/queriesGraphQL/useAccountList';
import { useRoles } from 'hooks/queriesGraphQL/useRoles';
import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';

import { useStyles } from './styles';

interface ProfileFormProps {
  control: any;
  register: any;
  setValue: any;
}

export const ProfileForm = ({ control, register, setValue }: ProfileFormProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [email, setEmail] = useState('');

  const accounts = useAccountList({
    page: 0,
    perPage: 100,
    query: '',
    aggregationRefs: 'accounts',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    ignoreDate: 'true'
  });

  const roles = useRoles({});

  const userOptions = accounts.data.accounts.map(account => {
    return {
      id: account.id,
      name: account.name,
      email: account.email,
      userId: account.userId
    };
  });

  const roleOptions = roles.data.map(role => {
    return {
      label: role.name,
      value: String(role.id)
    };
  });

  useEffect(() => {
    register('userId');
  }, [register]);

  return (
    <Box>
      <Controller
        name="name"
        control={control}
        render={({ onChange }) => (
          <Autocomplete
            onChange={(event, value) => {
              onChange(value ? value.name : '');
              setEmail(value ? value.email : '');
              setValue('userId', value?.userId, {});
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.email === value.email}
            getOptionLabel={option => (option ? option.name : '')}
            options={userOptions}
            className={classes.select}
            renderOption={option => (
              <>
                {option.name}
                <small className={classes.helpertext}>{option.email}</small>
              </>
            )}
            renderInput={params => (
              <Input label="Name" name="name" {...params} inputRef={register} />
            )}
          />
        )}
      />

      <Input label="Email" name="email" value={email} disabled />

      <Box mt={2}>
        <Typography variant="h6">Permissions</Typography>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
            <Typography variant="body1">Team</Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.roleContainer} padding={1}>
              <Typography variant="body1" className={classes.roleText}>
                Member Role
              </Typography>
            </Box>
            <Select
              options={roleOptions}
              name="roleId"
              label="Role"
              required={true}
              control={control}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
