import { AlertCenterType } from 'types/external/AlertCenter';
import { useQuery } from 'urql';

type UseAlertCenterParams = {
  page: number;
  size: number;
  query: string;
  gte: number;
  pause?: boolean;
};

type UseAlertCenterData = {
  data: AlertCenterType[];
  total: number;
};

const GetAlertsQuery = `
    query(
        $from: Int!,
        $size: Int!,
        $query: String!,
        $gte: Int!
    ) {
        alerts(
            from: $from,
            size: $size,
            query: $query,
            gte: $gte
        ) {
          data {
            id
            createdAt
            updatedAt
            deletedAt
            uid
            orgUid
            ruleId
            title
            cause
            origin
            severity
            status
            assignedId
            entityUid
            entity
            externalAggregateKey
            integrationUid
            alertHappenedAt
          }
          total
        }
    }
`;

export function useAlertCenter({ page, size, query, gte, pause = false }: UseAlertCenterParams) {
  const from = Math.max(page - 1, 0) * size;

  const [{ fetching, data }, reexecuteQuery] = useQuery<{ alerts: UseAlertCenterData }>({
    query: GetAlertsQuery,
    variables: {
      from,
      size,
      query,
      gte
    },
    pause
  });

  return { fetching, data, reexecuteQuery };
}
