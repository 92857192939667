import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useMutation as useMutationGraphql } from 'urql';

import actions from 'redux/actions';
import reducer from 'redux/reducer/members';
import useGetTeamMembers from 'views/Teams/hooks/useGetTeamMembers';

import DataGrid from '../../components/DataGrid';

import FormAddUser from './FormAddUser';

const CreateMember = `#graphql
  mutation(
    $name: String!
    $teamId: Float!
    $userId: Float!
    $roleId: Float! 
    $isActive: Boolean!
  ){
    createMember(
      name: $name 
      teamId: $teamId 
      userId: $userId
      roleId: $roleId 
      isActive: $isActive
    ){
      id
      teamId
      userId
    }
  }
`;

const List = ({ cols, entity, disabled, notificationSetup }) => {
  const dispatch = useDispatch();
  const { members_new } = useSelector(({ members_new }) => ({ members_new }));
  const { id: teamId } = useParams();

  const getTeamMembers = `#graphql
    query($teamId: Int!) {
      getTeamMembersV2(teamId: $teamId) {
        teamId
        id
        role{
          name
        }
        user{
          displayName
          email
          photoURL
          profile {
            phone
            verifiedPhone
          }
        }
      }
    }
  `;

  const DeleteMember = `#graphql 
    mutation($memberId: Int!){
      deleteMemberV2(memberId: $memberId)
    }
  `;

  const [resultQuery, reexecuteQuery] = useGetTeamMembers({
    getTeamMembers,
    teamId: Number(teamId)
  });

  const [, deleteMemberMutation] = useMutationGraphql(DeleteMember);

  const [, createMemberMutation] = useMutationGraphql(CreateMember);

  const onCreateMember = async data => {
    await createMemberMutation({
      name: '',
      teamId: Number(teamId),
      userId: Number(data.userId),
      roleId: Number(data.roleId),
      isActive: true
    })
      .then(() => {
        reexecuteQuery();
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  };

  const onDeleteMember = async ({ id }) => {
    await deleteMemberMutation({ memberId: Number(id) })
      .then(() => {
        reexecuteQuery();
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  };

  const graphqlResult = () => {
    if (resultQuery.data && resultQuery.data.getTeamMembersV2) {
      return resultQuery.data.getTeamMembersV2;
    }

    return [];
  };

  const graphqlData = graphqlResult();

  const fetch = React.useCallback(() => {
    dispatch({
      type: actions.MEMBERS_FETCH
    });
  }, [dispatch]);

  const onChangeAutocomplete = React.useCallback(
    payload => {
      dispatch({
        type: actions.MEMBERS_AUTOCOMPLETE,
        payload,
        meta: { reducer }
      });
    },
    [dispatch]
  );

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <FormAddUser
        members={members_new}
        disabled={disabled}
        onAddMember={onCreateMember}
        onChangeAutocomplete={onChangeAutocomplete}
      />
      <DataGrid
        cols={cols}
        entity={entity}
        graphqlData={graphqlData}
        removeHandler={onDeleteMember}
        notificationSetup={notificationSetup}
      />
    </>
  );
};

export default List;
