import { Box, Divider, Typography } from '@material-ui/core';
import { urqlClient } from 'App';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { UserProvider } from 'types/external/UserProvider';

import DownloadAppView from 'components/DownloadAppView';
import ContactMethod, { Contact, Contacts } from 'views/Account/components/ContactMethod';
import FormProfile, { FormInputs as ProfileFormInputs } from 'views/Account/components/FormProfile';

import { useStyles } from '../../styles';

const mockAppMethod = [{ method: 'app', value: 'Iphone 12', verified: true }];

const GeneralTab = () => {
  const classes = useStyles();

  const profileForm = useForm<ProfileFormInputs>({});

  // eslint-disable-next-line no-console
  const profileFormHandleSubmit = (inputs: ProfileFormInputs) => console.log(inputs);

  const { data } = useQuery(['userProviders'], {
    queryFn: () =>
      urqlClient
        .query<{ userProviders: UserProvider[] }>(
          `#graphql
              query {
                userProviders {
                  id
                  provider
                  editable
                  config {
                    value
                    verified
                  }
                }
              }
            `
        )
        .toPromise()
  });

  const contacts: Contacts = data?.data?.userProviders
    ? data?.data?.userProviders.map(userProvider => ({
        id: userProvider.id,
        active: true,
        editable: userProvider.editable,
        method: userProvider.provider as Contact['method'],
        value: userProvider.config.value,
        verified: userProvider.config.verified
      }))
    : [];

  return (
    <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
      <Box
        component="form"
        onSubmit={profileForm.handleSubmit(profileFormHandleSubmit)}
        display="flex"
        gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <FormProfile form={profileForm} />
          </Box>
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        Contact Methods
      </Typography>

      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <ContactMethod
              contacts={contacts}
              label="E-mail"
              type="email"
              buttonProps={{ label: 'Email' }}
            />
            <ContactMethod
              contacts={contacts}
              label="Voice"
              type="voice"
              buttonProps={{ label: 'Add phone number' }}
            />
            <ContactMethod
              contacts={contacts}
              label="WhatsApp"
              type="whatsapp"
              buttonProps={{ label: 'Add phone number' }}
            />
            <ContactMethod
              contacts={contacts}
              label="SMS"
              type="sms"
              buttonProps={{ label: 'Add phone number' }}
            />
            <ContactMethod
              contacts={contacts}
              label="Telegram"
              type="telegram"
              buttonProps={{ label: 'Telegram' }}
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Box display="flex" gridGap="3rem">
        <Box display="flex" flexDirection="column" flex={2} gridGap={12}>
          <Typography className={classes.subTitle} variant="h3">
            Mobile App
          </Typography>
          {mockAppMethod ? (
            <ContactMethod
              strongLabel="App"
              disableEdit={true}
              contacts={[
                { method: 'app', value: 'Iphone 12', verified: true, active: true, editable: false }
              ]}
              type="app"
            />
          ) : (
            <DownloadAppView />
          )}
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralTab;
