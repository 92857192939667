import { UserProviderSettings } from 'components/NotificationRules';

export const contactNotificationRulesMockAlert: UserProviderSettings = [
  {
    active: true,
    type: 'alert',
    id: 1,
    provider: {
      id: 1,
      editable: false,
      provider: 'whatsapp',
      config: {
        value: '+55-71986237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'alert',
    id: 2,
    provider: {
      id: 2,
      editable: false,
      provider: 'email',
      config: {
        value: 'vitor.franca@elven.works',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'alert',
    id: 3,
    provider: {
      id: 3,
      editable: false,
      provider: 'voice',
      config: {
        value: '+55-71986237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'alert',
    id: 4,
    provider: {
      id: 4,
      editable: false,
      provider: 'sms',
      config: {
        value: '+55-71986237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'alert',
    id: 5,
    provider: {
      id: 5,
      editable: false,
      provider: 'telegram',
      config: {
        value: '6237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'alert',
    id: 6,
    provider: {
      id: 6,
      editable: false,
      provider: 'app',
      config: {
        value: 'iphone j. Laricia - iphone 12 - v.1',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'alert',
    id: 7,
    provider: {
      id: 7,
      editable: false,
      provider: 'all',
      config: {
        value: '',
        verified: false
      }
    }
  }
];
export const contactNotificationRulesMockIncident: UserProviderSettings = [
  {
    active: true,
    type: 'incident',
    id: 1,
    provider: {
      id: 1,
      editable: false,
      provider: 'whatsapp',
      config: {
        value: '+55-71986237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'incident',
    id: 2,
    provider: {
      id: 2,
      editable: false,
      provider: 'email',
      config: {
        value: 'vitor.franca@elven.works',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'incident',
    id: 3,
    provider: {
      id: 3,
      editable: false,
      provider: 'voice',
      config: {
        value: '+55-71986237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'incident',
    id: 4,
    provider: {
      id: 4,
      editable: false,
      provider: 'sms',
      config: {
        value: '+55-71986237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'incident',
    id: 5,
    provider: {
      id: 5,
      editable: false,
      provider: 'telegram',
      config: {
        value: '6237773',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'incident',
    id: 6,
    provider: {
      id: 6,
      editable: false,
      provider: 'app',
      config: {
        value: 'iphone j. Laricia - iphone 12 - v.1',
        verified: false
      }
    }
  },
  {
    active: true,
    type: 'incident',
    id: 7,
    provider: {
      id: 7,
      editable: false,
      provider: 'all',
      config: {
        value: '',
        verified: false
      }
    }
  }
];
