import CheckBox from 'components/CheckBox';
import InputPhoneNumber from 'components/InputPhoneNumber';

import { Props } from './types';

const FormWithPhoneNumber = ({ form, type, checkboxLabel }: Props) => {
  return (
    <>
      <input hidden ref={form.register} value={type} name="method" />
      <InputPhoneNumber
        required={true}
        name="value"
        disabled={false}
        defaultValue=""
        control={form.control}
      />
      <CheckBox
        inputRef={form.register}
        defaultChecked={false}
        label={checkboxLabel!}
        name="reusePhoneNumber"
      />
    </>
  );
};

export default FormWithPhoneNumber;
