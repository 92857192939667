import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    '&.Mui-disabled': {
      opacity: 'initial'
    }
  }
}));
