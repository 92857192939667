import { useState } from 'react';

import { Box, Card, Tooltip, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { UseFormMethods, useForm } from 'react-hook-form';

import { Button, CustomButtonProps } from 'components/Button';

import { CreateMethodDialog } from '../Dialogs/Generics/CreateMethod';
import { DeleteMethodDialog } from '../Dialogs/Generics/DeleteMethod';
import { EditMethodDialog } from '../Dialogs/Generics/EditMethod';

import ContactMethodMenu from './Menu';
import { NotVerifiedWarning } from './NotVerifiedWarning';
import { useStyles } from './styles';
import { useContactMethod } from './useContactMethod';

export type Method = 'email' | 'voice' | 'whatsapp' | 'sms' | 'telegram' | 'app';

export type Inputs = {
  value: string;
  method: Method;
  reusePhoneNumber?: boolean;
};

export type Contact = {
  id?: number;
  method: Method;
  value: string | null;
  active: boolean;
  verified: boolean;
  editable: boolean;
};

export type Contacts = Contact[];

interface ButtonProps extends CustomButtonProps {
  label: string;
}

type Props = {
  label?: string;
  strongLabel?: string;
  type: Method;
  contacts: Contacts;
  buttonProps?: ButtonProps;
  disableEdit?: boolean;
};

type Dialogs = Record<
  DialogActions,
  {
    [key in Method]?: (props: {
      onClose: () => void;
      form: UseFormMethods<any>;
      onSubmit: () => void;
      isCreating: boolean;
      type: any;
      strongLabel?: string;
      currentContact: Contact;
    }) => JSX.Element;
  }
>;

export type DialogActions = 'create' | 'edit' | 'delete';

const dialogs: Dialogs = {
  create: {
    whatsapp: props => <CreateMethodDialog {...props} />,
    email: props => <CreateMethodDialog {...props} />,
    telegram: props => <CreateMethodDialog {...props} />,
    voice: props => <CreateMethodDialog {...props} />,
    sms: props => <CreateMethodDialog {...props} />
  },
  edit: {
    whatsapp: props => <EditMethodDialog {...props} />,
    email: props => <EditMethodDialog {...props} />,
    telegram: props => <EditMethodDialog {...props} />,
    voice: props => <EditMethodDialog {...props} />,
    sms: props => <EditMethodDialog {...props} />
  },
  delete: {
    whatsapp: props => <DeleteMethodDialog {...props} />,
    email: props => <DeleteMethodDialog {...props} />,
    telegram: props => <DeleteMethodDialog {...props} />,
    voice: props => <DeleteMethodDialog {...props} />,
    sms: props => <DeleteMethodDialog {...props} />,
    app: props => <DeleteMethodDialog {...props} />
  }
};

const ContactMethod = ({
  disableEdit,
  strongLabel,
  label,
  type,
  contacts,
  buttonProps = { label: '' }
}: Props) => {
  const { label: buttonLabel, ...restButtonProps } = buttonProps;

  const form = useForm<Inputs>({
    defaultValues: {
      value: '',
      method: type,
      reusePhoneNumber: false
    }
  });

  const [menuOptionsAnchorEl, setMenuOptionsAnchorEl] = useState<null | HTMLElement>(null);

  const [dialogAction, setDialogAction] = useState<DialogActions | null>(null);

  const currentContact = contacts.find(contact => contact.method === type) ?? {
    method: type,
    value: '',
    active: false,
    verified: false,
    editable: false
  };

  const classes = useStyles();

  const handleCloseDialogAction = () => {
    setDialogAction(null);
  };

  const handleOpenMenuOptions = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(event.currentTarget);
  };
  const handleCloseMenuOptions = () => {
    setMenuOptionsAnchorEl(null);
  };

  const Dialog = dialogAction ? dialogs[dialogAction][type] : null;

  const { onSubmit, formatContactMethodValue, isLoading } = useContactMethod({
    contacts,
    currentContact,
    handleCloseDialogAction
  });

  return (
    <>
      <Box display="flex" flexDirection="column" gridGap={12}>
        {label && <Typography className={classes.contactLabel}>{label}</Typography>}

        {currentContact.value && (
          <Card className={classes.contactValueBox}>
            <Typography>{formatContactMethodValue(currentContact?.value)}</Typography>
            {!currentContact.verified && <NotVerifiedWarning />}

            <div className="no-print">
              <Tooltip title="More Options" arrow>
                <Button
                  startIcon={null}
                  variant="outlined"
                  onClick={handleOpenMenuOptions}
                  className={classes.menuOptionButton}>
                  <MoreVert />
                </Button>
              </Tooltip>
            </div>
          </Card>
        )}

        {!currentContact?.verified && (
          <Box>
            <Button onClick={() => setDialogAction('create')} variant="text" {...restButtonProps}>
              {currentContact?.value ? 'Enter code' : buttonLabel}
            </Button>
          </Box>
        )}
      </Box>
      {Dialog && (
        <Dialog
          strongLabel={strongLabel}
          onSubmit={form.handleSubmit(onSubmit)}
          currentContact={currentContact}
          form={form}
          type={type}
          isCreating={isLoading}
          onClose={handleCloseDialogAction}
        />
      )}
      <ContactMethodMenu
        disableEdit={Boolean(disableEdit)}
        openDialogEdit={() => setDialogAction('edit')}
        openDialogDelete={() => setDialogAction('delete')}
        menuAnchorEl={menuOptionsAnchorEl}
        handleClose={handleCloseMenuOptions}
      />
    </>
  );
};

export default ContactMethod;
