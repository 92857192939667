import { Box } from '@material-ui/core';

const AppStore = () => {
  return (
    <Box display="flex" flexDirection="column">
      <img
        alt="download_on_the_app_store_badge"
        src="/images/download_on_the_app_store_badge.svg"
        height={44}
        width={150}
      />
      <Box mt={4} ml={4}>
        <img alt="email" src="/images/qr_code_mobile_app.svg" height={221} width={219} />
      </Box>
    </Box>
  );
};

export default AppStore;
