import { MenuItem } from '@material-ui/core';

import { Menu } from 'components/Menu';

type Props = {
  handleClose: () => void;
  menuAnchorEl: null | HTMLElement;
  openDialogEdit: () => void;
  openDialogDelete: () => void;
  disableEdit: boolean;
};

const ContactMethodMenu = ({
  handleClose,
  menuAnchorEl,
  openDialogDelete,
  openDialogEdit,
  disableEdit
}: Props) => {
  return (
    <Menu onClose={handleClose} anchorEl={menuAnchorEl}>
      <MenuItem disabled={disableEdit} onClick={openDialogEdit}>
        Edit
      </MenuItem>
      <MenuItem onClick={openDialogDelete}>Delete</MenuItem>
    </Menu>
  );
};

export default ContactMethodMenu;
