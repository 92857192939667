import { useQuery } from 'urql';

export const GetRespondersQuery = `#graphql
query{
  getRespondersByOrgUid(
    size: 500, 
    sortKey: "name",  
    sortValue: "ASC"
  ){

    data {
      name,
      type,
      uid
    }
  }
}
`;

export function useResponders() {
  return useQuery<{
    getRespondersByOrgUid: {
      data: {
        name: string;
        type: 'teams' | 'users';
        uid: string;
      }[];
    };
  }>({
    query: GetRespondersQuery
  });
}
