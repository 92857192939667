import { Dispatch, SetStateAction } from 'react';

import { DeleteExternalIntegrationMutation } from 'graphqlQueries/deleteExternalIntegration';
import { useDispatch } from 'react-redux';
import { ExternalIntegrationFromIndexer } from 'types/external/ExternalService';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { DeleteDialog } from 'componentsV4/DeleteDialog';
import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import actions from 'redux/actions';

type ExternalIntegrationDeleteDialogProps = {
  open: boolean;
  handleClose: () => void;
  externalIntegrationTitle: string;
  externalIntegrationUid: string;
  externalServices: ExternalIntegrationFromIndexer[];
  setExternalServices: Dispatch<SetStateAction<ExternalIntegrationFromIndexer[]>>;
  setDeletedItem: Dispatch<SetStateAction<string>>;
};

export function ExternalIntegrationDeleteDialog({
  open,
  handleClose,
  externalIntegrationTitle,
  externalIntegrationUid,
  externalServices,
  setExternalServices,
  setDeletedItem
}: ExternalIntegrationDeleteDialogProps) {
  const [{ fetching: deletingExternalIntegration }, deleteExternalIntegration] = useMutation<
    {},
    { uid: string }
  >(DeleteExternalIntegrationMutation);

  const dispatch = useDispatch();

  const handleDeleteExternalIntegration = async () => {
    const response = await deleteExternalIntegration({
      uid: externalIntegrationUid
    });

    if (response.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: getErrorMessageFromUrql(response.error) },
        ga: { category: 'ERROR' }
      });

      handleClose();

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'External Service successfully deleted'
    });

    handleClose();

    const newExternalServices = externalServices.filter(
      (externalService: ExternalIntegrationFromIndexer) =>
        externalService.uid !== externalIntegrationUid
    );

    setExternalServices(newExternalServices);
    setDeletedItem(externalIntegrationUid);
  };

  return (
    <DeleteDialog
      open={open}
      title={`Delete ${externalIntegrationTitle}`}
      handleClose={() => handleClose()}
      handleRemove={handleDeleteExternalIntegration}>
      {deletingExternalIntegration && <LoadingOverlay />}
      Are you sure you want to delete the external service{' '}
      <strong>{externalIntegrationTitle}</strong>?
    </DeleteDialog>
  );
}
