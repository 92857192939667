import { ScoreFunction, SearchParams } from 'types/external/Common';
import { ExternalIntegrationsFromIndexer } from 'types/external/ExternalService';
import { useQuery } from 'urql';

const ExternalIntegrationsFromIndexerQuery = `#graphql
    query(
      $from: Int!
      $size: Int!
      $query: String!
      $aggregationRefs: String
      $aggregationKeys: String!
      $aggregationValues: String
      $termKey: String
      $termValue: [String!]
      $betweenKey: String
      $betweenValues: String
      $sortKey: String
      $ignoreDate: String
      $functions: [SearchFunction!]
      $maxBoost: Float
      $scoreMode: String
      $boostMode: String
      $minScore: Float
    ) {
      getExternalIntegrationsFromIndexer(
      from: $from
      size: $size
      query: $query
      aggregationRefs: $aggregationRefs
      aggregationKeys: $aggregationKeys
      aggregationValues: $aggregationValues
      termKey: $termKey
      termValue: $termValue
      betweenKey: $betweenKey
      betweenValues: $betweenValues
      sortKey: $sortKey
      ignoreDate: $ignoreDate
      functions: $functions
      maxBoost: $maxBoost
      scoreMode: $scoreMode
      boostMode: $boostMode
      minScore: $minScore
    ) {
      data {
        uid
        name
        origin
        status
      }
      total
    }
  }
`;

export function useExternalIntegrationsList({
  page,
  perPage,
  query,
  aggregationKeys,
  aggregationRefs,
  aggregationValues,
  termKey,
  termValue,
  betweenKey,
  betweenValues,
  sortKey,
  ignoreDate,
  functions,
  maxBoost,
  scoreMode,
  boostMode,
  minScore,
  pause
}: {
  page: number;
  perPage: number;
  query?: string;
  aggregationKeys: string;
  aggregationRefs?: string;
  aggregationValues?: string;
  termKey?: string;
  termValue?: string[];
  betweenKey?: string;
  betweenValues?: string;
  sortKey?: string;
  ignoreDate?: string;
  functions?: ScoreFunction[];
  maxBoost?: number;
  scoreMode?: string;
  boostMode?: string;
  minScore?: number;
  pause: boolean;
}) {
  const from = Math.max(page - 1, 0) * perPage;

  return useQuery<ExternalIntegrationsFromIndexer, SearchParams>({
    query: ExternalIntegrationsFromIndexerQuery,
    pause,
    variables: {
      from,
      size: perPage,
      query,
      aggregationKeys,
      aggregationRefs,
      aggregationValues,
      termKey,
      termValue,
      betweenKey,
      betweenValues,
      sortKey,
      ignoreDate,
      functions,
      maxBoost,
      scoreMode,
      boostMode,
      minScore
    }
  });
}
