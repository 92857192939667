import { useEffect } from 'react';

import { Box, useTheme } from '@material-ui/core';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useSSOIntegrations } from 'hooks/queriesGraphQL/useSSOIntegrations';

import { SSOIntegrationCard } from './components/SSOIntegrationCard';

export type PlatformIntegration = 'github' | 'gitlab' | 'bitbucket';

export function TabSso() {
  const theme = useTheme();

  const [{ data, fetching }, refetchSSOIntegrations] = useSSOIntegrations();

  useEffect(() => {
    refetchSSOIntegrations({ requestPolicy: 'network-only' });
  }, [refetchSSOIntegrations]);

  const ssoIntegrations = data?.ssoIntegrations ?? [];

  const hasConnectedIntegrations = ssoIntegrations.length > 0;

  return (
    <Box style={{ marginLeft: 0, position: 'relative' }}>
      {fetching && <LoadingOverlay isBlock />}

      {!fetching && hasConnectedIntegrations && (
        <Box display="flex" gridGap={theme.spacing(4)} width="100%">
          {ssoIntegrations.map(integration => (
            <SSOIntegrationCard key={integration.id} type={integration.type} connected={true} />
          ))}
        </Box>
      )}

      {!fetching && !hasConnectedIntegrations && (
        <Box display="flex" gridGap={theme.spacing(4)}>
          <SSOIntegrationCard type="google" />
          <SSOIntegrationCard type="microsoft" />
        </Box>
      )}
    </Box>
  );
}
