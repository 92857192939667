import dayjs from 'dayjs';
import { InsightsRespondersMTTR } from 'types/external/Insights';
import { useQuery } from 'urql';

import { DataTable } from './DataTable';
import { MetricsTitle } from './MetricsTitle';

const GetMTTRByResponder = `#graphql
  query ($startDate: String!, $endDate: String!) {
    insights {
      responders {
        mttr(startDate: $startDate endDate: $endDate) {
          responderId
          responderName
          responderType
          mttr
        }
      }
    }
  }
`;

export function MTTRByResponderMetrics() {
  const endDate = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');

  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery<{
    insights: {
      responders: {
        mttr: InsightsRespondersMTTR[];
      };
    };
  }>({
    query: GetMTTRByResponder,
    variables: {
      startDate,
      endDate
    }
  });

  const mttrByResponder = data?.insights?.responders?.mttr || [];

  return (
    <DataTable
      title={
        <MetricsTitle
          title="Highest MTTR by Responder"
          tooltipTitle="Ranking of responders with the highest MTTR."
        />
      }
      fetching={fetching}
      columns={[
        {
          name: 'Responder ID',
          selector: row => row.responderId
        },
        {
          name: 'Responder Name',
          selector: row => row.responderName
        },
        {
          name: 'Responder Type',
          selector: row => row.responderType
        },
        {
          name: 'Responder MTTR',
          selector: row => row.mttr
        }
      ]}
      data={mttrByResponder}
    />
  );
}
