import { useCallback, useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Backdrop, Box, CircularProgress, Divider } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { DeleteEntity } from 'components/DeleteEntity';
import Dialog from 'components/Dialog';
import useDeleteMembers from 'hooks/queriesGraphQL/useDeleteMembers';
import useGetMember from 'hooks/queriesGraphQL/useGetMember';
import useUpdateMember from 'hooks/queriesGraphQL/useUpdateMember';

import { EditProfileForm } from './Form';
import schema from './schema';
import { useStyles } from './styles';

interface AddMemberDialogProps {
  open: boolean;
  handleChangeDialog: () => void;
  teamId: number;
  memberId: number;
  refetchList: () => void;
}

export const EditMember = ({
  open,
  handleChangeDialog,
  teamId,
  memberId,
  refetchList
}: AddMemberDialogProps) => {
  const classes = useStyles();

  const [openDelete, setOpenDelete] = useState(false);

  const { deleteMember, isLoading: loadingDeleteMember } = useDeleteMembers();

  const handleChangeDelete = useCallback(() => {
    setOpenDelete(!openDelete);
  }, [openDelete, setOpenDelete]);

  const removeMember = () => {
    deleteMember({ memberId }).then(() => {
      handleChangeDelete();
      handleChangeDialog();
      refetchList();
    });
  };

  const { updateMember, isLoading: loadingUpdateMember } = useUpdateMember();

  const { data: memberData } = useGetMember({
    memberId,
    teamId,
    executeQuery: open
  });

  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: {
      roleId: String(memberData?.roleId)
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  useEffect(() => {
    reset(memberData);
  }, [reset, memberData]);

  const editMember = (data: any) => {
    const payload = {
      isActive: true,
      teamId,
      roleId: Number(data.roleId),
      id: memberId
    };

    updateMember(payload).then(() => {
      handleChangeDialog();
      refetchList();
    });
  };

  return (
    <>
      <Dialog
        title="Edit"
        open={open}
        onClose={handleChangeDialog}
        showCloseButton
        onSubmit={handleSubmit(editMember)}
        actions={[
          {
            label: 'Delete user',
            variant: 'outlined',
            className: classes.deleteUser,
            color: 'default',
            onClick: handleChangeDelete
          },
          {
            label: 'Cancel',
            variant: 'outlined',
            className: classes.cancelButton,
            onClick: handleChangeDialog,
            color: 'default'
          },
          {
            label: 'Edit user',
            variant: 'contained',
            className: classes.buttons,
            type: 'submit'
          }
        ]}>
        <Box>
          <form onSubmit={handleSubmit(editMember)}>
            <EditProfileForm control={control} register={register} member={memberData} />
          </form>

          <DeleteEntity
            open={openDelete}
            handleClose={handleChangeDelete}
            handleRemove={removeMember}
            entityName="member"
            disabled={false}
            isLoading={loadingDeleteMember}
          />

          <Backdrop open={loadingUpdateMember} className={classes.backdrop}>
            <CircularProgress color="primary" size={90} thickness={5} />
          </Backdrop>

          <Divider className={classes.divider} />
        </Box>
      </Dialog>
    </>
  );
};
