import { Box } from '@material-ui/core';

import AppStore from './AppStore';
import GooglePlay from './GooglePlay';

const DownloadAppView = () => {
  return (
    <Box display="flex" gridGap={44}>
      <AppStore />
      <GooglePlay />
    </Box>
  );
};

export default DownloadAppView;
