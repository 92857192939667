import { useState } from 'react';

import { Box } from '@material-ui/core';

import { FilterToolbar } from 'components/FilterToolbar';
import { customElasticQuery } from 'helpers/customElasticQuery';
import useGetMembers from 'hooks/queriesGraphQL/useGetMembers';

import MemberList from './List';

const MemberTab = ({ teamId }: { teamId: number }) => {
  const [search, setSearch] = useState('');

  const { data: members, reexecuteQuery: refetchList } = useGetMembers({
    from: 0,
    size: 100,
    query: customElasticQuery({
      queryFilters: [
        { key: 'team_id', value: String(teamId) },
        { key: 'name', value: search, isRegex: true }
      ]
    })
  });

  return (
    <>
      <Box>
        <FilterToolbar placeholder="Search by name" setSearch={setSearch}></FilterToolbar>

        <MemberList members={members} refetchList={refetchList} />
      </Box>
    </>
  );
};

export default MemberTab;
