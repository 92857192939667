import { useState, useCallback } from 'react';

import { IconButton, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import { DeleteEntity } from 'components/DeleteEntity';
import { Menu } from 'components/Menu';
import { useDeleteMembers } from 'hooks/queriesGraphQL/useDeleteMembers';
import actions from 'redux/actions';
import { EditMember } from 'views/Teams/v2/components/EditMember';

import { useStyles } from './styles';

interface RowProps {
  memberId: number;
  teamId: number;
  roleId: number;
  userId: number;
  name: string;
}

interface MenuActionsProps {
  row: RowProps;
  refetchList: () => void;
}

export function MenuActions({ row, refetchList }: MenuActionsProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);

  const { deleteMember, isLoading: isLoadingDeleteMember } = useDeleteMembers();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleToogleEdit = useCallback(() => {
    setOpenEdit(!openEdit);
  }, [openEdit, setOpenEdit]);

  const handleToggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeMember = () => {
    deleteMember({ memberId: row.memberId }).then(() => {
      handleToogleDeleteDialog();
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Member successfully removed'
      });
      refetchList();
    });
  };

  const handleToogleDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(!openDeleteDialog);
  }, [setOpenDeleteDialog, openDeleteDialog]);

  return (
    <>
      <IconButton onClick={handleToggleMenu}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={anchorEl} onClose={handleClose} placement="left-start">
        <MenuItem className={classes.menuItem} onClick={handleToogleEdit}>
          Edit
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleToogleDeleteDialog}>
          Delete
        </MenuItem>
      </Menu>

      <EditMember
        handleChangeDialog={handleToogleEdit}
        open={openEdit}
        memberId={row.memberId}
        teamId={row.teamId}
        refetchList={refetchList}
      />

      <DeleteEntity
        open={openDeleteDialog}
        handleClose={handleToogleDeleteDialog}
        handleRemove={removeMember}
        entityName="member"
        disabled={false}
        isLoading={isLoadingDeleteMember}
      />
    </>
  );
}
