import { DeleteTeamMutation } from 'graphqlQueries/deleteTeam';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { DeleteDialog } from 'componentsV4/DeleteDialog';
import actions from 'redux/actions';

type TeamDeleteDialogProps = {
  open: boolean;
  handleClose: () => void;
  id: number;
  name?: string;
  onDelete: () => void;
};

export function TeamDeleteDialog({ open, handleClose, id, name, onDelete }: TeamDeleteDialogProps) {
  const [{ fetching: deletingTeam }, deleteTeam] = useMutation<{}, { id: number }>(
    DeleteTeamMutation
  );

  const dispatch = useDispatch();

  const handleDeleteTeam = async () => {
    const response = await deleteTeam({
      id: id
    });

    if (response.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: new Error('Cannot delete Team. Please try again'),
        ga: { category: 'ERROR' }
      });

      handleClose();

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Team successfully deleted'
    });

    handleClose();

    onDelete();
  };

  return (
    <DeleteDialog
      open={open}
      title={`Delete ${name}`}
      handleClose={() => handleClose()}
      handleRemove={handleDeleteTeam}>
      {deletingTeam && <LoadingOverlay />}
      Are you sure you want to delete the <strong>{name}</strong> Team?
    </DeleteDialog>
  );
}
