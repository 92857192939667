import { useCallback, useState } from 'react';

import { Button } from 'components/Button';

import { AddMemberDialog } from './Dialog';

export const AddMemberButton = ({ teamId }: { teamId: number }) => {
  const [open, setOpen] = useState(false);

  const handleChangeDialog = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <>
      <Button onClick={handleChangeDialog} title="Add Member">
        Member
      </Button>
      <AddMemberDialog open={open} handleChangeDialog={handleChangeDialog} teamId={teamId} />
    </>
  );
};
