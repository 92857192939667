import { Paper, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import ReactApexChart from 'react-apexcharts';
import { InsightsIncidentTotalResponders } from 'types/external/Insights';
import { useQuery } from 'urql';

import { Skeleton } from 'components/Skeletron';

import { MetricsTitle } from './MetricsTitle';

const GetIncidentTotalResponders = `#graphql
  query ($startDate: String!, $endDate: String!) {
    insights {
      responders {
        total(
          startDate: $startDate
          endDate: $endDate
        ) {
          responderName
          total
        }
      }
    }
  }
`;

export function ResponderIncidentVolume() {
  const theme = useTheme();

  const endDate = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  const startDate = dayjs()
    .subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery<{
    insights: {
      responders: {
        total: InsightsIncidentTotalResponders[];
      };
    };
  }>({
    query: GetIncidentTotalResponders,
    variables: {
      startDate,
      endDate
    }
  });

  const chartSeries =
    data?.insights?.responders?.total?.map(item => ({
      y: item.total,
      x: item.responderName
    })) ?? [];

  const hasData = chartSeries.length > 0;

  const chartHeight = hasData ? '100%' : '110%';

  return (
    <Paper
      variant="outlined"
      elevation={1}
      style={{
        padding: theme.spacing(1, 2, 0),
        height: theme.spacing(45),
        maxHeight: theme.spacing(45)
      }}>
      <MetricsTitle
        title="Responder Incident Volume"
        tooltipTitle="Number of incidents assigned per responder/team."
      />

      {fetching && <Skeleton height="90%" />}

      {!fetching && (
        <ReactApexChart
          series={[
            {
              name: 'Incidents',
              data: chartSeries
            }
          ]}
          options={{
            chart: {
              type: 'bar',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              }
            },
            fill: {
              opacity: 1,
              colors: ['#328D63']
            },
            plotOptions: {
              bar: {
                distributed: true,
                dataLabels: {
                  position: 'top'
                }
              }
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                colors: [theme.palette.grey[700]],
                fontWeight: 'normal'
              }
            },
            tooltip: {
              enabled: true
            },
            legend: {
              show: false
            },
            xaxis: {
              labels: {
                trim: true,
                maxHeight: theme.spacing(15),
                minHeight: theme.spacing(15),
                hideOverlappingLabels: false,
                style: {
                  colors: theme.palette.grey[700],
                  fontWeight: 'normal'
                }
              }
            },
            yaxis: {
              title: {
                text: 'Incidents'
              }
            },
            grid: {
              xaxis: {
                lines: {
                  show: true
                }
              }
            }
          }}
          height={chartHeight}
          type="bar"
        />
      )}
    </Paper>
  );
}
