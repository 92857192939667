import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentNewrelicGuide = ({
  orgUid,
  token,
  integrationUid
}: GuideParams): GuideType => {
  return {
    name: 'Newrelic',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/newrelic/${integrationUid}?token=${token}`,
    snippetWarning: {
      message:
        "During the testing phase, make sure to change the issueState field in the payload to 'open' so that it is possible to validate incident creation, and also change the issueTime to the desired timestamp in milliseconds. For more information, refer to our documentation through the ",
      linkText: 'link.',
      linkHref: 'https://elven.works/help/external-service-new-relic/'
    },
    snippets: [
      {
        key: 'Payload',
        value: `
      { 
        "issueId": {{json issueId}},
        "issueTitle": {{json issueTitle}},
        "issueDescription": "{{accumulations.conditionDescription.[0] }}",
        "issueState": {{#if issueClosedAt}}"closed"{{else if issueAcknowledgedAt}}"acknowledged"{{else}}"open"{{/if}},
        "issueTime": "{{ createdAt }}",
        "severity": {{ json priority }}
      }`
      }
    ]
  };
};
