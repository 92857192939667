import { Box, Divider, Typography } from '@material-ui/core';

import NotifySwitch from 'components/NotificationRules/NotifySwitch';
import { Skeleton } from 'components/Skeletron';

import { useStyles } from './styles';

const SkeletonNotifySwitch = () => {
  return (
    <Box display="flex" flexDirection="row" gridGap={10}>
      <Skeleton width={44} height={22} />
      <Skeleton width={400} height={22} />
    </Box>
  );
};
const SkeletonNotifySwitchList = () => {
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
    </Box>
  );
};

export type UserProviderSettings = {
  id: number;
  active: boolean;
  type: string;
  provider: {
    id: number;
    editable: boolean;
    config: {
      value: string;
      verified: boolean;
    };
    provider: string;
  };
}[];

const NotificationsRules = ({
  contactMethodsAlert,
  contactMethodsIncident,
  fetchingIncidentRules,
  fetchingAlertRules,
  refetchIncidents,
  refetchAlerts
}: {
  contactMethodsAlert: UserProviderSettings;
  contactMethodsIncident: UserProviderSettings;
  fetchingIncidentRules: boolean;
  fetchingAlertRules: boolean;
  refetchIncidents: () => void;
  refetchAlerts: () => void;
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
      <Typography className={classes.subTitle} variant="h3">
        When an alert is opened:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          {fetchingAlertRules ? (
            <SkeletonNotifySwitchList />
          ) : (
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <NotifySwitch refetch={refetchAlerts} contacts={contactMethodsAlert} method="email" />
              <NotifySwitch
                refetch={refetchAlerts}
                contacts={contactMethodsAlert}
                method="whatsapp"
              />
              <NotifySwitch refetch={refetchAlerts} contacts={contactMethodsAlert} method="voice" />
              <NotifySwitch refetch={refetchAlerts} contacts={contactMethodsAlert} method="sms" />
              <NotifySwitch
                refetch={refetchAlerts}
                contacts={contactMethodsAlert}
                method="telegram"
              />
              <NotifySwitch refetch={refetchAlerts} contacts={contactMethodsAlert} method="app" />
            </Box>
          )}
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        When an incident is opened:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          {fetchingIncidentRules ? (
            <SkeletonNotifySwitchList />
          ) : (
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="email"
              />
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="whatsapp"
              />
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="voice"
              />
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="sms"
              />
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="telegram"
              />
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="app"
              />
            </Box>
          )}
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        Incident response notification management:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <NotifySwitch
              refetch={refetchIncidents}
              contacts={[
                {
                  active: true,
                  id: 999,
                  provider: {
                    id: 9999,
                    provider: 'all',
                    config: { value: 'aa', verified: true },
                    editable: true
                  },
                  type: 'all'
                }
              ]}
              method="all"
              label="Receiving notifications for alerts and incidents enabled"
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationsRules;
