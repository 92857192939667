import { Dispatch, SetStateAction, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { ExternalIntegrationFromIndexer } from 'types/external/ExternalService';

import { usePermission } from 'hooks/usePermission';
import { useStyles } from 'views/Postmortems/styles';

import { ExternalIntegrationDeleteDialog } from './DeleteDialog';

type MenuProps = {
  externalServiceUid: string;
  externalServiceTitle: string;
  externalServices: ExternalIntegrationFromIndexer[];
  setExternalServices: Dispatch<SetStateAction<ExternalIntegrationFromIndexer[]>>;
  setDeletedItem: Dispatch<SetStateAction<string>>;
};

export function MenuActions({
  externalServiceUid,
  externalServiceTitle,
  externalServices,
  setExternalServices,
  setDeletedItem
}: MenuProps) {
  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteExternalService = () => {
    handleClose();
    setDeleteDialogOpen(true);
  };

  const hasDeleteExternalServicePermission = usePermission(
    'ExternalIntegrations-delete-/external_integrations_delete'
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem
          className={classes.menuItem}
          onClick={handleDeleteExternalService}
          disabled={!hasDeleteExternalServicePermission}>
          Delete External Service
        </MenuItem>
      </Menu>

      <ExternalIntegrationDeleteDialog
        open={deleteDialogOpen}
        externalIntegrationUid={externalServiceUid}
        externalIntegrationTitle={externalServiceTitle}
        handleClose={() => setDeleteDialogOpen(false)}
        externalServices={externalServices}
        setExternalServices={setExternalServices}
        setDeletedItem={setDeletedItem}
      />
    </>
  );
}
