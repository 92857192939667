import React from 'react';

import { apmBase } from '@elastic/apm-rum';
import { Typography, Switch, Tooltip, FormControlLabel } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import { DeleteDialog } from 'componentsV4/DeleteDialog';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import { useStyles } from '../../styles';

const activateMonitoringMutation = `#graphql
    mutation ($resourceUid: String!){
        activateResourceMonitoring(resourceUid: $resourceUid)
    }
`;

const deactivateMonitoringMutation = `#graphql
    mutation ($resourceUid: String!){
        deactivateResourceMonitoring(resourceUid: $resourceUid)
    }
`;

export function MonitoringButton({
  disabled,
  resourceUid,
  resourceName,
  monitoringIsActive,
  reexecuteResourceQuery,
  setMonitoringLoading,
  inMaintenance
}: {
  disabled: boolean;
  resourceUid: string;
  resourceName: string;
  monitoringIsActive: boolean;
  reexecuteResourceQuery: () => void;
  setMonitoringLoading: (loading: boolean) => void;
  inMaintenance: boolean;
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [, activateMonitoring] = useMutation(activateMonitoringMutation);

  const [, deactivateMonitoring] = useMutation(deactivateMonitoringMutation);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleClose = () => {
    setMonitoringLoading(false);
    setOpenDeleteDialog(false);
  };

  const handleRemove = () => {
    deactivate();
    setOpenDeleteDialog(false);
  };

  const activate = () => {
    activateMonitoring({
      resourceUid
    }).then(async result => {
      setMonitoringLoading(false);

      if (result.error) {
        apmBase.captureError(new Error(`Error on activate monitoring: ${result.error}`));

        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Monitoring successfully enabled'
      });

      reexecuteResourceQuery();
    });
  };

  const deactivate = () => {
    deactivateMonitoring({
      resourceUid
    }).then(async result => {
      setMonitoringLoading(false);

      if (result.error) {
        apmBase.captureError(new Error(`Error on deactivate monitoring: ${result.error}`));

        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Monitoring successfully disabled'
      });

      reexecuteResourceQuery();
    });
  };

  const handleToggleMonitoring = () => {
    setMonitoringLoading(true);

    if (monitoringIsActive) {
      setOpenDeleteDialog(true);

      return;
    }

    activate();
  };

  const buttonTitle = () => {
    if (disabled) {
      return '';
    }

    return monitoringIsActive ? 'Deactivate Monitoring' : 'Activate Monitoring';
  };

  const isAllowedToEnableMonitoring = usePermission(
    'MonitoringController-post-/activate_monitoring'
  );

  const Button = () => (
    <FormControlLabel
      label=""
      title={buttonTitle()}
      disabled={disabled || !isAllowedToEnableMonitoring}
      control={
        <Switch color="primary" checked={monitoringIsActive} onChange={handleToggleMonitoring} />
      }
    />
  );

  return (
    <>
      {!disabled ? (
        <Button />
      ) : (
        <Tooltip
          title={
            inMaintenance
              ? 'Enable monitoring disabled while maintenance in progress'
              : "Can't activate monitoring because the agent is not installed"
          }>
          <span>
            <Button />
          </span>
        </Tooltip>
      )}

      <Typography variant="body1" className={classes.textTitle}>
        {monitoringIsActive ? 'Monitoring is active' : 'Monitoring is inactive'}
      </Typography>

      <DeleteDialog
        open={openDeleteDialog}
        title="Deactivate monitoring"
        handleRemove={handleRemove}
        handleClose={handleClose}
        deleteText="DEACTIVATE">
        <Typography className={classes.deleteDialogText}>
          Are you sure you want to <strong>deactivate</strong> the <strong>{resourceName}</strong>{' '}
          monitoring? Once done you will <strong>not be able to see your metrics</strong> until it
          is <strong>activated</strong> again.
        </Typography>
      </DeleteDialog>
    </>
  );
}
