import { Box } from '@material-ui/core';
import {
  contactNotificationRulesMockAlert,
  contactNotificationRulesMockIncident
} from 'mocks/notification-rules';

import NotificationsRules from 'components/NotificationRules';

const NotificationRulesTab = () => {
  const mockPromise = new Promise(resolve => {
    resolve(1);
  });
  return (
    <Box>
      <NotificationsRules
        refetchAlerts={() => mockPromise}
        refetchIncidents={() => mockPromise}
        contactMethodsAlert={contactNotificationRulesMockAlert}
        contactMethodsIncident={contactNotificationRulesMockIncident}
        fetchingAlertRules={false}
        fetchingIncidentRules={false}
      />
    </Box>
  );
};

export default NotificationRulesTab;
