import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useQuery } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const GetAlertsStateQuery = `#graphql
query alertsState(
  $from: Float!
  $size: Float!
  $sortKey: String
  $sortValue: String
  $query: String
  $betweenKey: String
  $betweenValues: String
){
  alertsState(
    from: $from
    size: $size 
    sortKey: $sortKey
    sortValue: $sortValue
    query: $query
    betweenKey: $betweenKey
    betweenValues: $betweenValues
  ){
    data {
      id
      uid
      title
      status
      severity
      alertHappenedAt
      createdAt
      updatedAt
    }
    total
  }
}
`;

const GetAlertsStateQueryDeprecated = `#graphql
query alertsState(
  $from: Float!
  $size: Float!
  $sortKey: String
  $sortValue: String
  $query: String
){
  alertsState(
    from: $from
    size: $size 
    sortKey: $sortKey
    sortValue: $sortValue
    query: $query
  ){
    data {
      id
      uid
      title
      status
      severity
      alertHappenedAt
      createdAt
      updatedAt
    }
    total
  }
}
`;

export const useAlertsState = ({
  pause = false,
  from = 0,
  size = 500,
  sortKey = 'alert_happened_at',
  sortValue = 'DESC',
  query = '',
  betweenKey = 'alert_happened_at',
  betweenValues = 'now-1y,now'
}: {
  pause?: boolean;
  from?: number;
  size?: number;
  sortKey?: string;
  sortValue?: string;
  query?: string;
  betweenKey?: string;
  betweenValues?: string;
}) => {
  const shouldUseAlertStateAggregation = useFeatureFlagUnleash('useAlertStateAggregation', {
    queryString: true
  });
  const [{ fetching, data }, reexecuteQuery] = useQuery<{
    alertsState: {
      data: {
        id: number;
        uid: string;
        title: string;
        status: StatusEnum;
        severity: SeverityStatusEnum;
        alertHappenedAt: string;
        createdAt: string;
        updatedAt: string;
      }[];
      total: number;
    };
  }>({
    query: shouldUseAlertStateAggregation ? GetAlertsStateQuery : GetAlertsStateQueryDeprecated,
    variables: {
      from: Math.max(from - 1, 0) * size,
      size,
      sortKey,
      sortValue,
      query: query,
      betweenKey,
      betweenValues
    },
    pause
  });

  return {
    fetching,
    reexecuteQuery,
    data
  };
};
