import { urqlClient } from 'App';
import { useMutation, useQueryCache } from 'react-query';

import { UserProvidersSettings } from 'hooks/useUserProvidersSettings';

const DisableUserProviderMutation = `#graphql
  mutation(
    $type: String!,
    $providerId: Float!
  ) {
    disableUserProvider(type: $type, providerId: $providerId)
  }
`;

const EnableUserProviderMutation = `#graphql
  mutation(
    $type: String!,
    $providerId: Float!
  ) {
    enableUserProvider(type: $type, providerId: $providerId)
  }
`;

type UpdateProviderActiveStateVariables = {
  type: string;
  providerId: number;
};

export function useNotifySwitch() {
  const queryCache = useQueryCache();

  const updateProviderAtQueryCache = (
    args: UpdateProviderActiveStateVariables & { active: boolean }
  ) =>
    queryCache.setQueryData(
      ['userProvidersSettings', args.type],
      (data?: UserProvidersSettings[]) =>
        data?.map(contact => {
          if (contact.provider.id === args.providerId) {
            return {
              ...contact,
              active: args.active
            };
          }

          return contact;
        })!
    );

  const [disableProvider] = useMutation(
    ({ type, providerId }: UpdateProviderActiveStateVariables) =>
      urqlClient
        .mutation<{ disableUserProvider: boolean }, UpdateProviderActiveStateVariables>(
          DisableUserProviderMutation,
          {
            type,
            providerId
          }
        )
        .toPromise(),
    {
      onMutate: args => updateProviderAtQueryCache({ ...args, active: false })
    }
  );

  const [enableProvider] = useMutation(
    ({ type, providerId }: UpdateProviderActiveStateVariables) =>
      urqlClient
        .mutation<{ enableUserProvider: boolean }, UpdateProviderActiveStateVariables>(
          EnableUserProviderMutation,
          {
            type,
            providerId
          }
        )
        .toPromise(),
    {
      onMutate: args => updateProviderAtQueryCache({ ...args, active: true })
    }
  );

  return {
    disableProvider,
    enableProvider
  };
}
