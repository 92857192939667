import { useQuery } from 'urql';

const GetMemberQuery = `#graphql
  query(
    $memberId: Int!
  ) {
    getMemberV2(memberId: $memberId) {
      roleId
      isActive
      user{
        displayName
        profile {
          displayName
          photoURL
          phone
          verifiedPhone
        }
        email
      }
    }
  }
`;

export const useGetMember = ({
  memberId,
  teamId,
  executeQuery
}: {
  memberId: number;
  teamId: number;
  executeQuery: boolean;
}) => {
  const [{ fetching, data }, reexecuteQuery] = useQuery({
    query: GetMemberQuery,
    variables: { memberId, teamId },
    pause: !executeQuery
  });

  return { data: data?.getMemberV2, fetching, reexecuteQuery };
};

export default useGetMember;
