import { useQuery } from 'urql';

const useStepGroupsContacts = ({ teamId }: { teamId: number }) => {
  const [contactsFromTeams] = useQuery({
    query: `#graphql
      query ($teamId: Int!) {
        stepGroupsContactsFromTeams(
          teamId: $teamId
        ) {
          id
          user{
            displayName
          }
        }
      }
    `,
    variables: { teamId: Number(teamId) }
  });

  const contactsQueryFromTeams =
    contactsFromTeams.data?.stepGroupsContactsFromTeams.map((contact: any) => {
      return {
        id: contact.id,
        displayName: contact.user.displayName
      };
    }) || [];

  const contacts = contactsQueryFromTeams;
  return contacts;
};

export default useStepGroupsContacts;
