import React, { useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'componentsV4/Loading';
import useGetTeam from 'hooks/queriesGraphQL/useGetTeam';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { TeamsFormData } from 'views/ServicesHub/adapters/new/teams';
import { LayoutType } from 'views/ServicesHub/forms';

import { schema } from './schema';
import { useStyles } from './styles';

const createTeamWithMSTeamsMutation = `#graphql
  mutation(
    $name: String!,
  ){
    createTeamWithMSTeams(
      name: $name, 
    ){
      id
      name
    }
  }
`;

const updateTeamMutation = `#graphql
  mutation(
    $name: String!,
    $teamId: Float!
  ){
    updateTeam(
      name: $name,
      teamId: $teamId
    ){
      id
      name
    }
  }
`;

type FormValues = {
  name: string;
  description?: string;
};

export function TeamsLayout({ Form, isEdit }: LayoutType) {
  const classes = useStyles();

  const { uid } = useParams<{ uid: string }>();
  const teamId = Number(uid);

  const { data: team, fetching: isFetchingTeams } = useGetTeam({ teamId: Number(teamId) });

  const [{ fetching: isCreating }, createTeam] = useMutation(createTeamWithMSTeamsMutation);
  const [{ fetching: isUpdating }, updateTeam] = useMutation(updateTeamMutation);

  const defaultValues = {
    name: ''
  };

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(schema)
  });

  const { reset } = form;

  React.useEffect(() => {
    reset({
      name: team?.name
    });
  }, [reset, team]);

  const hasPermissionCreate = usePermission('TeamController-post-/teams');
  const hasPermissionEdit = usePermission('TeamController-put-/teams/:id');
  const [saving, setSaving] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (data: TeamsFormData) => {
    if (isEdit) {
      const teamsResponse = await updateTeam({
        teamId: team?.id,
        name: data.name
      });

      if (teamsResponse.error) {
        setSaving(false);
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on update service' }
        });

        // eslint-disable-next-line no-console
        console.error(teamsResponse.error);
        apm.captureError(teamsResponse.error);
        return;
      }

      setSaving(false);
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Team update successfully'
      });
      history.push(`/teams/${team?.id}`);
      return;
    }

    const teamsResponse = await createTeam({
      name: data.name
    });

    if (teamsResponse.error) {
      setSaving(false);
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on create team' }
      });

      // eslint-disable-next-line no-console
      console.error(teamsResponse.error);
      apm.captureError(teamsResponse.error);
      return;
    }

    setSaving(false);
    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Team created successfully'
    });

    history.push(`/teams/${teamsResponse.data?.createTeamWithMSTeams?.id}`);
  };

  const fetching = isCreating || isUpdating || isFetchingTeams;

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      gridGap="2.5rem"
      flexDirection="column"
      position="relative"
      onSubmit={form.handleSubmit(handleSubmit)}>
      <Box>
        {(fetching || saving) && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h4">
          Configure a Teams
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <Form form={form} />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Support configuration
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              If you have any questions, click here to access the documentation.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.goBack()}
          disabled={isCreating}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!(isEdit ? hasPermissionEdit : hasPermissionCreate) || isCreating}>
          {isEdit ? 'Save' : 'Create'} Teams
        </Button>
      </Box>
    </Box>
  );
}
